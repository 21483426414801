<template>
    <v-container fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office ID"
                                    @change="(event) => updateSales(event)"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="sales"
                                    :items="saless"
                                    item-value="sales_id"
                                    item-text="nama"
                                    label="Sales"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                    :disabled="disabled"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-autocomplete
                                    solo
                                    v-model="customer"
                                    :loading="loadingCust"
                                    :items="items"
                                    item-value="customer_id"
                                    item-text="nama"
                                    :search-input.sync="search"
                                    cache-items
                                    hide-no-data
                                    label="Customer"
                                    :return-object="true" 
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-text-field
                                    solo
                                    v-model="order_id"
                                    label="Order ID"
                                    single-line
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="outstanding"
                                    :items="outstandings"
                                    item-value="value"
                                    item-text="label"
                                    label="Outstanding Order"
                                    class="ma-0 pa-0"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date Order From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="3">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date Order to"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="submit()">Search</v-btn>
                            </v-col>
                            <!-- <v-col cols="2">
                                <v-btn
                                    class="mr-2 mt-1"
                                    color="error"
                                    elevation="2"
                                    large
                                    rounded
                                    @click="submit()"
                                >
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                    Search
                                </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 100px;" id="result1">
                <v-card>
                    <v-container fluid>
                        <v-row>
                            <v-card-title>
                                Result
                            </v-card-title>
                            <v-row align="center">
                                <v-col
                                    class="p-5"
                                    cols="12"
                                >
                                    <div id="chartContainer1" style="height: 400px; width: 100%;"></div>
                                </v-col>
                            </v-row>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1400px">                
                    <v-card>
                        <v-card-title>Detail</v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            :headers="header_details"
                                            :items="debt_details"
                                            :options.sync="option_details"
                                            :loading="loading_details"
                                            loading-text="Please wait, retrieving data"
                                            :server-items-length="totalItem_details"
                                            :page.sync="pagination_details"
                                            page-count="10"
                                            class="elevation-1"    
                                        >
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'KBT',
                disabled: false,
                href: '/admin/srmall',
                },
                {
                text: 'Data Analyze',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Order to Ship Performance',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            offices: [],
            office: '',
            saless:[],
            sales: '',
            customers:[],
            items:[],
            customer: '',
            loadingCust: false,
            display: 'none',
            loading_loader: false,
            sortBy: 'asc',
            sortName: '',
            raw_materials: [],
            search: null,
            order_id: '',
            outstandings: [
                {
                    label: 'Yes',
                    value: 'Y'
                },
                {
                    label: 'No',
                    value: 'N'
                }
            ],
            outstanding: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            invoice: '',
            summaryInv: {},
            dialog: false,
            detailItem:{},
            header_details:[
                { text: 'Item Num', value: 'item_num', sortable: false },
                { text: 'SP', value: 'kka_sp', sortable: false },
                { text: 'Article', value: 'article_desc', sortable: false },
                { text: 'Ord Qty', value: 'order_qty', sortable: false },
                { text: 'Ord Ship', value: 'order_shipped', sortable: false },
                { text: 'Ord Retur', value: 'order_retur', sortable: false },
                { text: 'PO Qty', value: 'po_qty', sortable: false },
                { text: 'PO Rcv', value: 'po_rcv', sortable: false },
                { text: 'PO Retur', value: 'po_retur', sortable: false },
                { text: 'Kka Ord', value: 'kka_order', sortable: false },
                { text: 'Kka Plan', value: 'kka_plan', sortable: false },
                { text: 'Kka Prod', value: 'kka_prod', sortable: false },
                { text: 'Kka Wh', value: 'kka_wh', sortable: false },
                { text: 'Kka Ship', value: 'kka_ship', sortable: false },
                { text: 'Kka Retur', value: 'kka_returr', sortable: false },
                { text: 'Kka Replace', value: 'kka_replace', sortable: false },
                { text: 'Kka Stat', value: 'kka_stat', sortable: false }
            ],
            debt_details: [],
            option_details: {},
            loading_details: false,
            totalItem_details: 10,
            pagination_details: 1,
            x: window.matchMedia("(max-width: 991px)"),
            result1: '',
            txtStep: '',
            data_var: {
                entity_id : 'KBT',
                appl_id : 'WEBKBT'
            },
            group: '',
            disabled: false
        }
    },
    mounted(){
        this.getMaterial()
        this.result1 = document.getElementById("result1");
        this.getEnvConf()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'OFFICEID') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.office = res.data.data[i]['var_value']
                            this.getMaterial()
                            this.disabled = true
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'SALESID') {

                        if (res.data.data[i]['var_value'] != null) {
                            this.updateSales()
                            this.sales = res.data.data[i]['var_value']
                            this.disabled = true
                        }
                    }

                    
                }

            })
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = false;
            }
        },
        getChart1(dp1, dp2, dp3, dp4, dp5, dp6, container, title, subtitle){
            var chart1 = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: subtitle,
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                exportEnabled: true,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Hour Interval"
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    fontSize: 11,
                    itemMaxWidth: 150,
                    itemWrap: true ,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.onClick1,
                        showInLegend: true,
                        name: "ORD->APRV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                    },
                    {
                        type: "column",
                        click: this.onClick2,
                        showInLegend: true,
                        name: "APRV->PO",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                    },
                    {
                        type: "column",
                        click: this.onClick3,
                        showInLegend: true,
                        name: "PO->PO_APRV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                    },
                    {
                        type: "column",
                        click: this.onClick4,
                        showInLegend: true,
                        name: "PO_APRV->RLS",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                    },
                    {
                        type: "column",
                        click: this.onClick5,
                        showInLegend: true,
                        name: "RLS->RCV",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                    },
                    {
                        type: "column",
                        click: this.onClick6,
                        showInLegend: true,
                        name: "RCV->SHIP",
                        indexLabel: "{y}",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                    }
                ]
            });
            chart1.options.data[0].dataPoints = dp1;
            chart1.options.data[1].dataPoints = dp2;
            chart1.options.data[2].dataPoints = dp3;
            chart1.options.data[3].dataPoints = dp4;
            chart1.options.data[4].dataPoints = dp5;
            chart1.options.data[5].dataPoints = dp6;

            if (this.x.matches) {

                for(var i = 0; i < chart1.options.data.length; i++){
                    chart1.options.data[i].indexLabelFontSize = 6;
                }
                chart1.render();
            }

            chart1.render();

        },
        onClick1(e){    
            this.header_details = []
            this.header_details = [
                { text: 'Periode', value: 'Periode', sortable: false },
                { text: 'Office', value: 'office', sortable: false },
                { text: 'SalesId', value: 'sales_id', sortable: false },
                { text: 'Customer', value: 'customer', sortable: false },
                { text: 'Order ID', value: 'order_id', sortable: false },
                { text: 'Stat', value: 'stat', sortable: false },
                { text: 'PO ID', value: 'po_id', sortable: false },
                { text: 'Item Num', value: 'item_num', sortable: false },
                { text: 'Article', value: 'article_desc', sortable: false },
                { text: 'Stat Item', value: 'stat_item', sortable: false },
                { text: 'Date Order', value: 'dt_order', sortable: false },
                { text: 'Date Approve', value: 'dt_aprv', sortable: false },
                { text: 'Interval', value: 'result', sortable: false }
            ]
            this.txtStep = 'step1'
            this.showItem(e.dataPoint.label)
        },
        onClick2(e){
            this.header_details = []
            this.header_details = [
                { text: 'Periode', value: 'Periode', sortable: false },
                { text: 'Office', value: 'office', sortable: false },
                { text: 'SalesId', value: 'sales_id', sortable: false },
                { text: 'Customer', value: 'customer', sortable: false },
                { text: 'Order ID', value: 'order_id', sortable: false },
                { text: 'Stat', value: 'stat', sortable: false },
                { text: 'PO ID', value: 'po_id', sortable: false },
                { text: 'Item Num', value: 'item_num', sortable: false },
                { text: 'Article', value: 'article_desc', sortable: false },
                { text: 'Stat Item', value: 'stat_item', sortable: false },
                { text: 'Date Approve', value: 'dt_aprv', sortable: false },
                { text: 'Date PO', value: 'dt_po', sortable: false },
                { text: 'Interval', value: 'result', sortable: false }
            ]
            this.txtStep = 'step2'
            this.showItem(e.dataPoint.label)
        },
        onClick3(e){
            this.header_details = []
            this.header_details = [
                { text: 'Periode', value: 'Periode', sortable: false },
                { text: 'Office', value: 'office', sortable: false },
                { text: 'SalesId', value: 'sales_id', sortable: false },
                { text: 'Customer', value: 'customer', sortable: false },
                { text: 'Order ID', value: 'order_id', sortable: false },
                { text: 'Stat', value: 'stat', sortable: false },
                { text: 'PO ID', value: 'po_id', sortable: false },
                { text: 'Item Num', value: 'item_num', sortable: false },
                { text: 'Article', value: 'article_desc', sortable: false },
                { text: 'Stat Item', value: 'stat_item', sortable: false },
                { text: 'Date PO', value: 'dt_po', sortable: false },
                { text: 'Date PO Apprv', value: 'dt_po_aprv', sortable: false },
                { text: 'Interval', value: 'result', sortable: false }
            ]
            this.txtStep = 'step3'
            this.showItem(e.dataPoint.label)
        },
        onClick4(e){
            this.header_details = []
            this.header_details = [
                { text: 'Periode', value: 'Periode', sortable: false },
                { text: 'Office', value: 'office', sortable: false },
                { text: 'SalesId', value: 'sales_id', sortable: false },
                { text: 'Customer', value: 'customer', sortable: false },
                { text: 'Order ID', value: 'order_id', sortable: false },
                { text: 'Stat', value: 'stat', sortable: false },
                { text: 'PO ID', value: 'po_id', sortable: false },
                { text: 'Item Num', value: 'item_num', sortable: false },
                { text: 'Article', value: 'article_desc', sortable: false },
                { text: 'Stat Item', value: 'stat_item', sortable: false },
                { text: 'Date PO Apprv', value: 'dt_po_aprv', sortable: false },
                { text: 'Date Release', value: 'dt_release', sortable: false },
                { text: 'Interval', value: 'result', sortable: false }
            ]
            this.txtStep = 'step4'
            this.showItem(e.dataPoint.label)
        },
        onClick5(e){
            this.header_details = []
            this.header_details = [
                { text: 'Periode', value: 'Periode', sortable: false },
                { text: 'Office', value: 'office', sortable: false },
                { text: 'SalesId', value: 'sales_id', sortable: false },
                { text: 'Customer', value: 'customer', sortable: false },
                { text: 'Order ID', value: 'order_id', sortable: false },
                { text: 'Stat', value: 'stat', sortable: false },
                { text: 'PO ID', value: 'po_id', sortable: false },
                { text: 'Item Num', value: 'item_num', sortable: false },
                { text: 'Article', value: 'article_desc', sortable: false },
                { text: 'Stat Item', value: 'stat_item', sortable: false },
                { text: 'Date Release', value: 'dt_release', sortable: false },
                { text: 'Date Receive', value: 'dt_rcv', sortable: false },
                { text: 'Interval', value: 'result', sortable: false }
            ]
            this.txtStep = 'step5'
            this.showItem(e.dataPoint.label)
        },
        onClick6(e){
            this.header_details = []
            this.header_details = [
                { text: 'Periode', value: 'Periode', sortable: false },
                { text: 'Office', value: 'office', sortable: false },
                { text: 'SalesId', value: 'sales_id', sortable: false },
                { text: 'Customer', value: 'customer', sortable: false },
                { text: 'Order ID', value: 'order_id', sortable: false },
                { text: 'Stat', value: 'stat', sortable: false },
                { text: 'PO ID', value: 'po_id', sortable: false },
                { text: 'Item Num', value: 'item_num', sortable: false },
                { text: 'Article', value: 'article_desc', sortable: false },
                { text: 'Stat Item', value: 'stat_item', sortable: false },
                { text: 'Date Apprv', value: 'dt_aprv', sortable: false },
                { text: 'Date Receive', value: 'dt_rcv', sortable: false },
                { text: 'Date Shipped', value: 'dt_shipped', sortable: false },
                { text: 'Interval', value: 'result', sortable: false }
            ]
            this.txtStep = 'step6'
            this.showItem(e.dataPoint.label)
        },
        async updateSales(office){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang?officeid=${office ? office : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.saless = res.data.sales
            });
        },
        async querySelections (value) {
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/customer?search=${value}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.items = res.data
            });
        },
        close(){
            this.dialog = false
        },
        async getMaterial(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kbt/piutang`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data.office
                this.saless = res.data.sales
            });
        },
        async submit(){

            this.loading = true
            this.loading_loader = true
            this.display = 'block'

            var subtitle = '';
            var title = '';

            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

            if (this.date_from && this.date_to) {

                title = 'Periode '+this.date_from+'-'+this.date_to;
            }

            if (!this.date_from && this.date_to) {

                title = 'Periode <= '+this.date_to;
            }

            if (this.date_from && !this.date_to) {

                title = 'Periode >= '+this.date_from;
            }  

            if (this.office) {

                subtitle = subtitle+'Office:'+this.office+', '.trim();
            }

            if (this.sales) {

                subtitle = subtitle+'Salesman:'+this.sales+', '.trim();
            }

            if (this.order_id) {

                subtitle = subtitle+'OrderID:'+this.order_id+', '.trim();
            }

            if (this.customer) {

                subtitle = subtitle+'Customer:'+this.customer.nama+', '.trim();
            }

            if (this.outstanding) {

                subtitle = subtitle+'Outstanding:'+this.outstanding+', '.trim();
            }

            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/order_ship_performance?txtOfficeID=${this.office ? this.office : ''}&txtSalesID=${this.sales ? this.sales : ''}&txtOrderID=${this.order_id ? this.order_id : ''}&txtCustomer=${this.customer ? this.customer.customer_id : ''}&txtOutstanding=${this.outstanding ? this.outstanding : ''}&txtStart=${start_date}&txtEnd=${end_date}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {

                var dp1 =  []; var dp2 =  []; var dp3 =  []; var dp4 =  []; var dp5 =  []; var dp6 =  [];

                for (var i = 0; i < res.data.result.length; i++) {

                    dp1.push({  label: res.data.result[i].Periode,  y: parseFloat(res.data.result[i].a) });
                    dp2.push({  label: res.data.result[i].Periode,  y: parseFloat(res.data.result[i].b) });
                    dp3.push({  label: res.data.result[i].Periode,  y: parseFloat(res.data.result[i].c) });
                    dp4.push({  label: res.data.result[i].Periode,  y: parseFloat(res.data.result[i].d) });
                    dp5.push({  label: res.data.result[i].Periode,  y: parseFloat(res.data.result[i].e) });
                    dp6.push({  label: res.data.result[i].Periode,  y: parseFloat(res.data.result[i].f) });

                }
                var container  = "chartContainer1"; 
                this.result1.style.display = 'block';
                $('html, body').animate({
                    scrollTop: $("#result1").offset().top
                }, 1200)

                this.getChart1(dp1, dp2, dp3, dp4, dp5, dp6, container, title, subtitle)

                this.loading = false
                this.loading_loader = false

                
                
            });
        },
        showItem(item){
            this.dialog = true
            this.detailItem = item
            this.debt_details = []
            this.getDetail(1, 10, item)
        }, 
        async getDetail(page = 1, itemsPerPage = 10, item){
            this.loading_details = true
            await axios.get(`${process.env.VUE_APP_URL}/api/kbt/order_ship_performance/detail?txtPeriode=${item}&txtStep=${this.txtStep ? this.txtStep : 'step1'}&txtOfficeID=${this.office ? this.office.office_id : ''}&txtSalesID=${this.sales ? this.sales.sales_id : ''}&txtOrderID=${this.order_id ? this.order_id : ''}&txtCustomer=${this.customer ? this.customer.customer_id : ''}&txtOutstanding=${this.outstanding ? this.outstanding : ''}&page=${page > 1 ? page : ''}&pagination=${itemsPerPage}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading_details = false

                this.debt_details = res.data.data
                this.totalItem_details = res.data.total
                this.pagination_details = res.data.current_page
            })
        }
    },
    watch: {
        option_details:{
            handler () {
                const { page, itemsPerPage } = this.option_details
                if (this.detailItem) {
                    this.getDetail(page, itemsPerPage, this.detailItem)
                }
            },
            deep: true
        },
        search (val) {
            val && val !== this.customer && this.querySelections(val)
        },
    }
}
</script>